<template>
  <!-- :style="'background-image: url(' + require('@/assets/image/index/rollBg.png') + ');'" -->
  <router-link
    tag="div"
    :to="'/active/rollHome/' + item.id"
    class="w-100"
    style="margin-top:10px;"
    :class="item.status == 0 ? '' : 'filter-grayscale-1 '"
  >
    <!-- V3 -->
    <div class="roll-item-wrap">
      <div class="d-flex align-items-center justify-content-start">
        <img
          src="../../../assets/image/userCenter/user_profile.png"
          alt=""
          class="Headimg"
        />
      </div>
      <!-- 官方/主播 房间名 -->
      <div>
        <div>
          <p v-if="item.type == 0" class="family roll-text">官方</p>
          <p v-else class="family roll-text">主播</p>
          <!-- <img v-if="item.type == 0" src="@/assets/imgV2/roll/G.png" alt="">
          <img v-else src="@/assets/imgV2/roll/Z.png" alt=""> -->
        </div>
        <div style="color: #fff; text-align: center;font-size:16px;margin:10px 0;font-weight:bold;">
          {{ item.name }}
        </div>
      </div>
      <!-- <hr style="margin:0;color:white;border:1px solid #fff;"> -->
      <div
        class="roll-item-wrap-tow d-flex justify-content-around text-center kaitime"
      >
        <div>
          <div class="family">奖池</div>
          <div class="roll-item-wrap-tow-title">{{ item.award_bean }}</div>
        </div>
        <div>
          <div class="family">件数</div>
          <div class="roll-item-wrap-tow-title">{{ item.awards_count }}</div>
        </div>
        <div>
          <div class="family">人数</div>
          <div class="roll-item-wrap-tow-title">
            {{ item.join_number }}/{{ item.people_number }}
          </div>
        </div>
      </div>

      <!-- 枪支 -->
      <div class="w-100 d-flex">
        <div
          class="col-lg-4 col-4"
          v-for="(t, index) in item.awards"
          :key="index"
        >
          <div slot="reference" class="roll-item-wrap-cover">
            <img
              width="100%"
              style="height: 60px"
              class="rounded"
              :src="t.box_record.cover"
              alt=""
            />
            <div class="price w-100 text-center">{{ t.bean }}</div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <div class="roll-item-wrap-btn family">加入</div>
      </div>
      <!-- 开奖时间 -->
      <div class="w-100 text-center kaitime roll-item-wrap-end">
        开奖时间：{{ item.end_time }}
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "yoyoRollList",
  props: {
    item: Object,
  },
  data() {
    return {
      end_time: "",
    };
  },
 
  // async mounted() {
  //   this.showtime()
  // },
  methods: {},
};
</script>

<style scoped lang="scss">
.roll-item-wrap {
  font-size: 12px;
  position: relative;
  padding: 10px;
  // background-image: linear-gradient(#3876d4, #939898);
  border-radius: 15px;
  // box-shadow: 0 0 54px 5px #9fda35 inset;
    background: url("../../../assets/img/roll-bg.png")no-repeat;
    background-size: 100% 100%;
    z-index: -1;
  opacity:1;
  &-top {
    font-size: 18px;
  }

  &-btn {
    text-align: center;
    font-size: 16px;
    background-image: url("../../../assets/imgV2/roll/btn.png");
    background-size: 100% 100%;
  }

  &-tow {
    font-size: 18px;

    &-title {
      margin-bottom: 10px;
      font-size: 14px;
    }
  }

  &-end {
    font-size: 12px;
  }

  &-cover {
    // background: url('../../../assets/imgV2/roll/cover.png') center no-repeat;
    // background-size: 100% 100%;
    background-image: linear-gradient(#3b69ae00, #c1bcb7);
    border-radius: 10px;
    overflow: hidden;
  }
}

.content {
  &-con {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../../../assets/imgV2/roll/index-roll-item-bg.png");
    border: 1px solid transparent;
    transition: all 0.5s;

    &:hover {
      border: 1px solid var(--main-blue);
    }
  }

  &-item {
    border: 1px solid transparent;
    transition: all 0.5s;
    overflow: hidden;

    &:hover {
      border: 1px solid var(--pan-main);
      border-radius: 10px;
    }

    &:hover a {
      border: 2px solid var(--pan-main);
      border-radius: 15px;
    }

    &:hover .content-roll-item-time {
      transition: all 0.5s;
      color: var(--pan-main);
    }

    .box_record {
      transition: all 0.5s;
    }

    &:hover .box_record {
      transform: scale(1.2);
    }
  }

  &-roll {
    width: 100%;

    @media (max-width: 1200px) {
      overflow-x: scroll;
    }
  }

  /* 新加 */
  .border-green {
    border: 2px solid #48e082;
    box-shadow: 0px 0px 10px 2px #48e082;
  }

  .border-red {
    border: 2px solid #ff4861;
    box-shadow: 0px 0px 10px 2px #ff4861;
  }

  .roll-btn {
    border: 1px solid #ff4861 !important;
    background-color: #241118 !important;
  }

  &-top {
    border-radius: 20px;
    padding: 20px 0;
    background-image: linear-gradient(0deg, #27272e, #08080a);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-item {
    &-conter {
      width: 160px;
      height: 160px;
      background-color: #18181e;
      border-radius: 50%;
      //padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.5s;

      @media (max-width: 960px) {
        width: 120px;
        height: 120px;
      }

      &-cover {
        width: 100%;
      }

      &-user {
        transition: all 0.5s;
        width: 60px;
        height: 60px;
        padding: 5px;
        position: absolute;
        left: -30px;
        bottom: 10px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #131318;

        & img {
          width: 100%;
          height: 100%;
        }
      }

      &:hover &-user {
        transform: scale(1.5) rotate(-30deg);
      }

      &:hover {
        transform: rotate(30deg);
      }
    }
  }

  &-bottom {
    display: flex;
    padding: 0.7rem;

    &-left {
      padding: 10px;
      border-radius: 10px;
      background-color: #08080a;
    }

    &-right {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      border: 1px solid #48e082;
      background-color: rgba(17, 35, 25, 1);
    }
  }
}

.content-item-conte-cover-img {
  background-color: #101426;
  padding: 10px;
}

.roll-user {
  color: #ffffff;
  bottom: -5px;
  width: 70px;
  text-align: center;
  border-radius: 5px;
  padding: 4px 0;
  background-color: #f19919;

  @media (max-width: 960px) {
    padding: 1px 0;
    bottom: -8px;
  }
}
.roll-item-wrap-tow-title {
  margin-top: 20px;
  // color: #8dc67b;
  color: #007aff;
}
.kaitime {
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 8px;
}
.Headimg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ffffff;
  margin: auto;
}
.roll-item-wrap-btn {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  color: rgb(255, 255, 255);
  font-size: 25px;
  -webkit-text-stroke: 1px black;
}

.roll-text {
  font-size: 30px;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 2px white;
  text-align: center;
}
.roll-item-wrap-cover{
  // background-image: linear-gradient(#3b69ae00, #c1bcb7);
  background-color: #ccc;
    border-radius: 10px;
    overflow: hidden;
}
.price{
  padding-top: 5px;
  padding-bottom: 5px;
  background: url('../../../assets/img/price.png');
}
</style>
