

import {request} from "../index";
function getRoomsList(page,status,type){
    return request({
        url: `/rooms?page=${page}&status=${status}&type=${type}`,
        method:'get'
    })
}

function getUserRooms(){
    return request({
        url: `/user/rooms`,
        method:'get'
    })
}

function getRoomsJoin(data){
    return request({
        url: `/room/join`,
        method:'post',
        data
    })
}
function getRoomsDetail(id){
    return request({
        url: `/room/detail?id=${id}`,
        method:'get'
    })
}

function getStorage(page){
    return request({
        url: `/storage?page=${page}`,
        method:'get',

    })
}

export default {
    getRoomsList,
    getRoomsJoin,
    getRoomsDetail,
    getStorage,
    getUserRooms
}
